
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91templateVersionId_938VSPbqFbloMeta } from "/vercel/path0/apps/template-admin/pages/edit/[templateId]/[templateVersionId].vue?macro=true";
import { default as indexXapLqUsFwTMeta } from "/vercel/path0/apps/template-admin/pages/index.vue?macro=true";
import { default as _91contractId_93LXoNsV7jOfMeta } from "/vercel/path0/apps/template-admin/pages/preview/[contractId].vue?macro=true";
import { default as _91country_93p72wfvxuXTMeta } from "/vercel/path0/apps/template-admin/pages/style-demo/[country].vue?macro=true";
export default [
  {
    name: "edit-templateId-templateVersionId",
    path: "/edit/:templateId()/:templateVersionId()",
    meta: _91templateVersionId_938VSPbqFbloMeta || {},
    component: () => import("/vercel/path0/apps/template-admin/pages/edit/[templateId]/[templateVersionId].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexXapLqUsFwTMeta || {},
    component: () => import("/vercel/path0/apps/template-admin/pages/index.vue")
  },
  {
    name: "preview-contractId",
    path: "/preview/:contractId()",
    component: () => import("/vercel/path0/apps/template-admin/pages/preview/[contractId].vue")
  },
  {
    name: "style-demo-country",
    path: "/style-demo/:country()",
    component: () => import("/vercel/path0/apps/template-admin/pages/style-demo/[country].vue")
  }
]