import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/vercel/path0/apps/template-admin/middleware/01.auth.global.ts";
import _02_45csrf_45global from "/vercel/path0/apps/template-admin/middleware/02.csrf.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45csrf_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}