export default defineNuxtRouteMiddleware(async (route) => {
  if (import.meta.dev && route.name === 'style-demo-country')
    return true

  const config = useRuntimeConfig()
  const { user } = useUser()
  const { getUser } = useFetchUser()

  if (user.value) {
    const bugsnag = useBugsnag()
    bugsnag.setUser(user.value.id, user.value.email, user.value.name)
  }

  if (!user.value) {
    try {
      const response = await getUser()
      user.value = response.data
    }
    catch {
      return navigateTo(config.public.loginUrl, {
        external: true,
      })
    }
  }
})
